import { useEffect, useRef, useState } from 'react';
import './BetsPage.scss';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { beautifyDbName, displayAmount, currencySymbols } from '../../helpers';
import request from '../../request';
import { mdiAngleRight, mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';

const itemsPerPage = 50;

const BetsPage = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const params = useParams();
  const navigate = useNavigate();

  const partners = useSelector((state) => state.app.partners);
  const games = useSelector((state) => state.app.games);

  useEffect(() => {
    if (page > 0) {
      request
        .get(`bets?page=${page}&limit=${itemsPerPage}`)
        .then((response) => {
          setData(response.data.data);
          setTotalPages(response.data.totalPages);
        })
        .catch((err) => {

        });
      setPage(parseInt(page));
      
      const tableContainerEl = document.querySelector('.table-container');

      tableContainerEl.scrollTo(tableContainerEl.scrollLeft, 0);
    }
  }, [page]);

  useEffect(() => {
    if (params.page > 0) {
      setPage(params.page);
    }
  }, [params.page]);

  const changePage = (targetPage) => {
    if (targetPage > 0 && targetPage <= totalPages) {
      navigate(`/bets/${targetPage}`);
    }
  };

  return (
    <div className="BetsPage">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Bet ID</th>
              <th>Player ID</th>
              <th>Partner</th>
              <th>Game</th>
              <th>Bet Amount</th>
              <th>Win Amount</th>
              <th>Result<br /><span className="fs-xs">Player</span></th>
              <th>Revenue<br /><span className="fs-xs">Casino</span></th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              partners.length > 0 && games.length > 0 && data.map((bet, i) => {
                const partner = partners.find((p) => p.id === bet.partner_id);
                const game = games.find((g) => g.key === bet.game_id);
                
                let result = parseFloat(-bet.bet_amount);
                
                result += bet.win_amount_after_gamble || bet.win_amount;

                return (
                  <tr
                    key={i}
                  >
                    <td className="fs-xs fw-500">#{bet.bet_id}</td>
                    <td className="fs-xs fw-500">#{bet.player_id}</td>
                    <td className="fs-xs fw-500">{beautifyDbName(partner.name)}</td>
                    <td className="fs-xs fw-500">{beautifyDbName(game.key)}</td>
                    <td className="">{displayAmount(bet.bet_amount, bet.currency)}</td>
                    <td className="fw-300">{bet.win_amount ? displayAmount(bet.win_amount, bet.currency) : displayAmount(0, bet.currency)}</td>
                    <td className={`${(result > 0 ? ' green fs-sm' : ' red')}`}>{(result > 0 ? '+' : '-')}{displayAmount(Math.abs(result), bet.currency)}</td>
                    <td className={`${(result > 0 ? ' red fs-sm' : ' green fw-500')}`}>{(result > 0 ? '-' : '+')}{displayAmount(Math.abs(result), bet.currency)}</td>
                    <td className="fs-md">{bet.date} {bet.time}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>

      <div className={`pagination`}>
        {
          page > 1
            ? <button className="btn btn-default border" onClick={() => changePage(page - 1)}>
                <Icon path={mdiArrowLeft} size={.5}></Icon>
              </button>
            : ''
        }
        {
          totalPages > 2 && page > 2
            ? <button className="btn btn-default border" onClick={() => changePage(1)}>{1}</button>
            : ''
        }
        {
          totalPages > 2 && page === totalPages
            ? <button className="btn btn-default border" onClick={() => changePage(page - 2)}>{page - 2}</button>
            : ''
        }
        {
          totalPages > 1 && page !== 1
            ? <button className="btn btn-default border" onClick={() => changePage(page - 1)}>{page - 1}</button>
            : ''
        }
        <button className="btn btn-dark border current">{page}</button>
        {
          totalPages > 1 && page < totalPages
            ? <button className="btn btn-default border" onClick={() => changePage(page + 1)}>{page + 1}</button>
            : ''
        }
        {
          totalPages > 3 && page === 1
            ? <button className="btn btn-default border" onClick={() => changePage(page + 2)}>{page + 2}</button>
            : ''
        }
        {
          page < totalPages - 1 && totalPages !== 1
            ? <button className="btn btn-default border" onClick={() => changePage(totalPages)}>{totalPages}</button>
            : ''
        }
        {
          page < totalPages
            ? <button className="btn btn-default border" onClick={() => changePage(page + 1)}>
                <Icon path={mdiArrowRight} size={.5}></Icon>
              </button>
            : ''
        }
      </div>
    </div>
  );
};

export default BetsPage;
