import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: 'app',
  initialState: {
    games: [],
    partners: [],
  },
  reducers: {
    setData: (state, action) => {
      for (let key in action.payload) {
        state[key] = action.payload[key];
      }
    },
  },
});

export default appSlice;
