import './Dashboard.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../helpers';

const Dashboard = (props) => {
  const [topGames, setTopGames] = useState([]);
  const [topPartners, setTopPartners] = useState([]);

  const games = useSelector((state) => state.app.games);
  const partners = useSelector((state) => state.app.partners);

  return (
    <div className="Dashboard">
      <div className="content">
        <div className="tools"></div>

        <div className="totals">
          <div className="left">
            <div className="total-bets">
              <span className="label">Total number of bets</span>

              <span className="amount">
                {/* {betsTotal} $ */}
              </span>
            </div>

            <div className="total-wins">
              <span className="label">Total number of wins</span>

              <span className="amount">
                {/* {winsTotal} $ */}
              </span>
            </div>
          </div>

          <div className="right">
            <div className="total-revenue">
              <span className="label">Total revenue by games</span>

              <span className="amount">
                {/* {revenue} $ */}
              </span>
            </div>

            <div className="total-profit">
              <span className="label">Profit by partners</span>

              <span className="amount">
                - $
              </span>
            </div>
          </div>
        </div>

        <div className="ranking">
          <div className="top-games">
            <div className="header">Top games by bets</div>

            <div className="list">
              {
                // topGames.slice(0, 5).map((game, i) => {
                //   return (
                //     <div
                //       key={i}
                //     >
                //       <span className="name">{game.key}</span>
                //       <span className="amount">{formatNumber(game.totalBets)}&nbsp;$</span>
                //     </div>
                //   );
                // })
              }
            </div>
          </div>

          <div className="top-partners">
            <div className="header">Top betting partners</div>

            <div className="list">
              {
                // topPartners.slice(0, 5).map((partner, i) => {
                //   return (
                //     <div
                //       key={i}
                //     >
                //       <span className="name">{partner.name}</span>
                //       <span className="amount">{formatNumber(partner.totalBets)}&nbsp;$</span>
                //     </div>
                //   );
                // })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
