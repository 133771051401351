import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import './overrides.scss';
import './classes.scss';
import Dashboard from './components/dashboard/Dashboard';
import Navbar from './components/navbar/Navbar';
import { useEffect } from 'react';
import request from './request';
import store from './store';
import appSlice from './store/app/appSlice';
import BetsPage from './components/bets-page/BetsPage';
import WinsPage from './components/wins-page/WinsPage';
import GamesPage from './components/games-page/GamesPage';
import PartnersPage from './components/partners-page/PartnersPage';
import SettingsPage from './components/settings-page/SettingsPage';
import Simulator from './components/simulator/Simulator';

function App() {

  useEffect(() => {
    getGames().then(() => {
      getPartners().then(() => {
        
      });
    });
  }, []);

  const getGames = () => {
    return request
      .get(`games`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          games: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  const getPartners = () => {
    return request
      .get(`operators`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          partners: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar></Navbar>
        
        <main>
          <Routes>
            <Route path="/" element={<Dashboard></Dashboard>}></Route>
            <Route path="/bets" element={<BetsPage></BetsPage>}></Route>
            <Route path="/bets/:page" element={<BetsPage></BetsPage>}></Route>
            <Route path="/wins" element={<WinsPage></WinsPage>}></Route>
            <Route path="/games" element={<GamesPage></GamesPage>}></Route>
            <Route path="/partners" element={<PartnersPage></PartnersPage>}></Route>
            <Route path="/settings" element={<SettingsPage></SettingsPage>}></Route>
            <Route path="/simulator" element={<Simulator></Simulator>}></Route>
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
