import { useEffect, useRef, useState, useCallback } from 'react';
import './GameSession.scss';
import { io } from 'socket.io-client';

// Constants for configuration
const MAX_LOGS = 50;
const PLAY_INTERVAL = 500;
const INITIAL_DELAY_MIN = 100;
const INITIAL_DELAY_MAX = 10000;

const GameSession = (props) => {
  // Combined state for better performance
  const [gameState, setGameState] = useState({
    socket: null,
    accessKey: null,
    message: null,
    logs: [],
    showLogs: false,
    bet: false,
    spinCount: 0,
    balance: 0,
    credit: 0,
    debit: 0,
    freespins: false
  });

  const playInterval = useRef(null);
  const socketRef = useRef(null);

  // Memoized socket event handlers
  const handleSocketEvents = useCallback((socketInstance) => {
    const onAnyHandler = (eventName, data) => {
      setGameState(prev => ({
        ...prev,
        message: { eventName, data }
      }));
    };

    const onBetHandler = (data) => {
      // Empty handler kept for consistency
    };

    const onReadyHandler = () => {
      socketInstance.emit('open', {
        accessKey: gameState.accessKey,
        dev: {
          // balance: 10000,
        },
      });
    };

    const onOpenHandler = (data) => {
      setGameState(prev => ({
        ...prev,
        bet: true,
        balance: data.balance,
        accessKey: data.accessKey
      }));
    };

    const onConnectErrorHandler = (err) => {
      console.error('Connection error:', err);
    };

    const onDisconnectHandler = () => {
      setGameState(prev => ({ ...prev, bet: false }));
    };

    socketInstance.onAny(onAnyHandler);
    socketInstance.on('bet', onBetHandler);
    socketInstance.on('ready', onReadyHandler);
    socketInstance.on('open', onOpenHandler);
    socketInstance.on('connect_error', onConnectErrorHandler);
    socketInstance.on('disconnect', onDisconnectHandler);

    return () => {
      socketInstance.offAny(onAnyHandler);
      socketInstance.off('bet', onBetHandler);
      socketInstance.off('ready', onReadyHandler);
      socketInstance.off('open', onOpenHandler);
      socketInstance.off('connect_error', onConnectErrorHandler);
      socketInstance.off('disconnect', onDisconnectHandler);
    };
  }, [gameState.accessKey]);

  // Initialize socket connection
  useEffect(() => {
    const connectionDelay = INITIAL_DELAY_MIN + Math.floor(Math.random() * (INITIAL_DELAY_MAX - INITIAL_DELAY_MIN));
    const timeout = setTimeout(() => {
      const socketInstance = io(`${window.location.protocol}//${window.location.hostname.replace('backoffice.', '')}:3001`, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 10000,
      });

      socketRef.current = socketInstance;
      setGameState(prev => ({ ...prev, socket: socketInstance }));

      const cleanupSocketEvents = handleSocketEvents(socketInstance);

      return () => {
        cleanupSocketEvents?.();
        socketInstance.disconnect();
      };
    }, connectionDelay);

    return () => {
      clearTimeout(timeout);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [handleSocketEvents]);

  // Handle incoming messages
  useEffect(() => {
    if (gameState.message !== null) {
      const processMessage = () => {
        const { eventName, data } = gameState.message;
        let profit = 0;
        let updates = {};
  
        if (eventName === 'bet') {
          profit = 1;
          updates.debit = gameState.debit + 1;
          
          if (data.win.length) {
            const winAmount = data.win.reduce((sum, line) => sum + line.amount, 0);
            profit -= winAmount;
            updates.credit = gameState.credit + winAmount;
          }
  
          if (data.freespins) {
            updates.freespins = data.freespins;
          }
  
          updates.spinCount = gameState.spinCount + 1;
          props.onProfit(profit);
          props.onSpin();
        } else if (eventName === 'play') {
          const remainingFreespins = data.freespins - data.freespinsPlayed;
          
          if (remainingFreespins === 0) {
            profit -= data.winAmount;
            updates.credit = gameState.credit + data.winAmount;
          }
  
          updates.freespins = remainingFreespins;
          updates.spinCount = gameState.spinCount + 1;
          props.onProfit(profit);
          props.onSpin();
        }
  
        // Update logs with new message (limited to MAX_LOGS)
        updates.logs = [
          { eventName, data },
          ...gameState.logs.slice(0, MAX_LOGS - 1)
        ];
  
        return updates;
      };
  
      const updates = processMessage();
      setGameState(prev => ({ ...prev, ...updates }));
    }
  }, [gameState.message]); // Only depend on message

  // Play interval handler
  useEffect(() => {
    const playAction = () => {
      if (gameState.bet && gameState.balance >= 1 && socketRef.current) {
        const payload = {
          gameId: props.gameId,
          currency: 'USD',
          casino: 1
        };

        if (gameState.freespins > 0) {
          socketRef.current.emit('play', payload);
        } else {
          socketRef.current.emit('bet', { ...payload, bet: 5 });
        }
      }
    };

    const intervalTime = PLAY_INTERVAL;
    playInterval.current = setInterval(playAction, intervalTime);

    return () => {
      if (playInterval.current) {
        clearInterval(playInterval.current);
      }
    };
  }, [gameState.bet, gameState.balance, gameState.freespins, props.gameId]);

  // Toggle logs visibility
  const toggleLogs = useCallback(() => {
    setGameState(prev => ({ ...prev, showLogs: !prev.showLogs }));
  }, []);

  // Format number for display
  const formatNumber = (value) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value));
  };

  const profit = gameState.debit - gameState.credit;
  const isProfitable = profit >= 0;

  return (
    <div className="GameSession">
      <div className="header">
        <div>
          <span className="fw-300">Player</span>
          &nbsp;#{props.id}
        </div>
        <div className="fw-700 fs-xs"><span>{gameState.spinCount}</span> Spins</div>
      </div>
      <div className="mt-2">
        <span className="fs-xs fw-700">BET</span> $1,00</div>
      <div className="statistics">
        <div>
          <span className="fs-sm" style={{ display: 'inline-flex', width: '50px' }}>Spent</span> 
          <span className="fw-400">${formatNumber(gameState.debit)}</span>
        </div>
        <div>
          <span className="fs-sm" style={{ display: 'inline-flex', width: '50px' }}>Won</span>
          <span className="fw-300">${formatNumber(gameState.credit)}</span>
        </div>
        <div className="d-flex flex-direction-row justify-content-start align-items-center">
          <span className="fs-sm" style={{ display: 'inline-flex', width: '50px' }}>Casino</span> 
          <div className={`ms-05${isProfitable ? ' green' : ' red'}`}>
            <span>{isProfitable ? '+' : '-'}</span>
            <span className="fw-500">${formatNumber(Math.abs(profit))}</span>
          </div>
        </div>
      </div>
      <span className={`mt-3 fs-sm fw-700${gameState.freespins == 0 ? ' ninja' : ''}`}>
        FREE SPINS {gameState.freespins}
      </span>
      <button
        className="btn-toggle-logs"
        onClick={toggleLogs}
      >
        Logs
      </button>
      <div className={`logs${!gameState.showLogs ? ' d-none' : ''}`}>
        {gameState.logs.map((log, i) => (
          <div key={i}>{log.eventName}: {JSON.stringify(log.data)}</div>
        ))}
      </div>
    </div>
  );
};

export default GameSession;
