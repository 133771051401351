import { useSelector } from 'react-redux';
import { useState, useCallback, useEffect, useMemo } from 'react';
import GameSession from '../game-session/GameSession';
import Icon from '@mdi/react';
import { 
  mdiHomePlusOutline, 
  mdiHomeMinusOutline,
  mdiRefresh
} from '@mdi/js';
import './Simulator.scss';
import { beautifyDbName } from '../../helpers';

// Constants
const PLAYER_COUNT_OPTIONS = [1, 10, 25, 50, 100, 200, 256, 500];
const NUMBER_FORMAT_OPTIONS = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

const Simulator = (props) => {
  const [step, setStep] = useState(0);
  const [gameKey, setGameKey] = useState('');
  const [sessionCount, setSessionCount] = useState(0);
  const [profit, setProfit] = useState(0);
  const [spinCount, setSpinCount] = useState(0);
  const games = useSelector((state) => state.app.games);

  // Memoized game list to prevent unnecessary re-renders
  const gameList = useMemo(() => (
    games.map((game, i) => (
      <div
        key={game.key} // Using game.key instead of index for better reconciliation
        className="item"
        onClick={() => {
          setGameKey(game.key);
          setStep(-1); // Transition state
          setTimeout(() => setStep(2), 0); // Next step in next tick
        }}
      >
        {beautifyDbName(game.key)}
      </div>
    ))
  ), [games]);

  // Memoized player count options
  const playerCountOptions = useMemo(() => (
    PLAYER_COUNT_OPTIONS.map((n) => (
      <div
        key={n}
        className="item"
        onClick={() => {
          setSessionCount(n);
          setStep(3);
        }}
      >
        {n}
        &nbsp;
      </div>
    ))
  ), []);

  // Optimized profit handler with functional updates
  const onProfit = useCallback((value) => {
    setProfit(prevProfit => prevProfit + value);
  }, []);

  // Optimized spin handler with functional updates
  const onSpin = useCallback(() => {
    setSpinCount(prevCount => prevCount + 1);
  }, []);

  // Format number with memoization
  const formatNumber = useCallback((num) => (
    new Intl.NumberFormat('de-DE', NUMBER_FORMAT_OPTIONS).format(Math.abs(num))
  ), []);

  // Generate game sessions with stable keys
  const renderGameSessions = useMemo(() => {
    if (step !== 3) return null;
    
    return Array.from({ length: sessionCount }, (_, i) => (
      <GameSession
        key={`session-${i}`} // More descriptive key
        id={i + 1}
        gameId={gameKey}
        onProfit={onProfit}
        onSpin={onSpin}
      />
    ));
  }, [step, sessionCount, gameKey, onProfit, onSpin]);

  // Cleanup function if needed
  useEffect(() => {
    return () => {
      // Any cleanup when component unmounts
    };
  }, []);

  return (
    <div className="Simulator">
      <button
        className={`btn-start kave-btn${step !== 0 ? ' d-none' : ''}`}
        onClick={() => setStep(1)}
      >
        <span className="kave-line"></span>
        Begin
      </button>

      <div className={`initialization-steps${step === 0 ? ' ninja' : ''}`}>
        {/* Step 1: Game Selection */}
        <div className={`step step-1${step === 1 ? ' current' : ''}`}>
          <span className="fw-500">Choose Game</span>
          <div className="game-list">
            {gameList}
          </div>
        </div>

        {/* Step 2: Player Count Selection */}
        <div className={`step step-2${step === 2 ? ' current' : ''}`}>
          <span className="fw-500">Choose Players</span>
          <div className="session-count-list">
            {playerCountOptions}
          </div>
        </div>
      </div>

      {/* Dashboard Controls */}
      <div className={`controls${step !== 3 ? ' ninja' : ''}`}>
        <div className={`house-profit ${profit > 0 ? 'green' : 'red'}`}>
          <Icon 
            path={profit > 0 ? mdiHomePlusOutline : mdiHomeMinusOutline} 
            size={2}
          />
          <span>${formatNumber(profit)}</span>
        </div>
        <div className="spin-count">
          {spinCount}
          <span className="ms-05">Spins</span>
        </div>
      </div>

      {/* Game Sessions Dashboard */}
      <div className={`dashboard${step === 3 ? '' : ' ninja'}`}>
        {renderGameSessions}
      </div>
    </div>
  );
};

export default Simulator;